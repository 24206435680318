@use "sass:math";

.sticky-nav-wrapper {
  background: $white;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
}

.state-directory-container {
  h1 {
    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }
  }

  .body-card {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      max-height: 800px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 606px;
    }

    &.ca-provinces {
      @include media-breakpoint-up(md) {
        max-height: 300px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 246px;
      }
    }

    div {
      margin-bottom: 13px;
    }
  }
}

.cities-container {
  > div {
    margin-bottom: 13px;
    min-width: 50%;
    width: 20%;

    @include media-breakpoint-up(md) {
      min-width: 33%;
    }

    @include media-breakpoint-up(lg) {
      min-width: auto;
    }
  }
}

.city-container {
  .body-card-places {
    padding: 20px 0 0;

    .city-businesses-container {
      flex: 1 1 auto;
    }

    .city-businesses-sidebar {
      flex: 0 0 375px;
      flex-direction: column;
      height: 100%;
      margin: 0 20px 20px;
      padding: 0 20px 20px;
      position: sticky;
      top: 70px;
      width: 375px;

      &.no-filters {
        top: 20px;

        &.has-floating-promo-cta {
          top: 100px;
        }
      }
    }
  }

  .no-results-card {
    @include el-light-border-bottom;
    @include el-light-border-top;

    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
      margin-top: 0;
      padding: 40px;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
      flex-direction: row;
    }

    div {
      font-size: 1.3125rem;
      font-weight: $font-weight-semibold;

      @include media-breakpoint-between(xs, lg) {
        margin-bottom: 20px;
      }
    }

    .btn {
      min-width: 168px;
    }
  }

  .search-page-cta {
    @include el-light-border-bottom;

    align-items: center;
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 40px;
    }

    // TODO(eric): delete this once we fix up the pagination controls a bit
    &:last-child {
      margin-bottom: 40px;
    }

    .cta-link-wrapper {
      align-items: center;
      color: $darker-gray-text;
      flex-direction: row;
      font-weight: $font-weight-normal;

      @include media-breakpoint-between(xs, lg) {
        border-left: 0;
        border-right: 0;
        justify-content: flex-start;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        padding: 40px;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px;
      }

      h2 {
        margin-bottom: 8px;
      }

      .mobile-cta-header {
        @include media-breakpoint-between(xs, md) {
          align-items: center;
          flex-direction: row;
          margin-bottom: 10px;
        }
      }
    }

    .cta-icon {
      border: 0;
      border-radius: 0;
      margin: 0 20px 0 0;
      width: 40px;

      @include media-breakpoint-up(md) {
        width: 60px;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 20px 20px 0;
      }
    }
  }

  .death-doulas-contact-cta {
    padding: 20px;

    @include media-breakpoint-between(xs, md) {
      @include el-light-border-bottom;
    }

    @include media-breakpoint-up(md) {
      padding: 40px;
    }

    &.add-border-top {
      @include el-light-border-top;
    }

    .signup-cta {
      margin-top: 40px;
    }

    button {
      margin: 0 auto;

      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }
    }
  }

  .business-filters-mobile-overlay {
    background: $white;
    height: 0%;
    left: 0;
    opacity: 0;
    overflow-y: scroll;
    padding: 40px 20px 0;
    position: fixed;
    top: 0;
    transition:
      opacity 0.35s,
      visibility 0.35s,
      height 0.35s;
    visibility: hidden;
    width: 100%;
    z-index: $zindex-modal;

    &.open {
      height: 100%;
      opacity: 1;
      visibility: visible;
    }

    .close-overlay {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 15px;
    }

    .filter-by {
      font-size: 2rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 40px;
    }

    .mobile-filter-controls-wrapper {
      flex-direction: column;
      height: calc(100% - 108px);
      justify-content: space-between;
    }

    .pill-btn {
      margin-bottom: 20px;
    }

    .star-rating-container {
      display: inline-flex;
    }

    .no-results-card-mobile {
      @include el-light-border-top;

      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin-top: 40px;
      padding-top: 20px;

      div {
        font-size: 1.3125rem;
        font-weight: $font-weight-semibold;
      }
    }

    .filter-submits {
      justify-content: space-between;

      .apply-filters {
        color: $secondary-brand-color;

        img {
          margin-left: 5px;
          margin-top: -3px;
          width: 20px;
        }
      }
    }
  }

  .business-filters-mobile {
    display: flex;
    justify-content: flex-start;
    padding: 20px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    > div,
    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    > a {
      color: $dark-gray-text;
    }

    .filters-overlay-toggle {
      @include el-border;

      align-items: center;
      border-radius: $border-radius-lg;
      display: flex;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;

      &.selected {
        border-color: $secondary-brand-color;
        border-width: 2px;
        width: auto;

        img {
          display: none;
        }
      }

      div {
        font-weight: $font-weight-semibold;
      }

      img {
        margin-top: 2px;
        width: 17px;
      }
    }
  }

  .applied-filter-mobile {
    align-items: center;
    background-color: $light-gray;
    border-radius: $border-radius-lg;
    height: 40px;
    justify-content: space-between;
    padding: 0 10px;

    div {
      img {
        margin-top: -4px;
        width: 19px;
      }
    }

    .remove-filter {
      margin-left: 10px;
      width: 12px;
    }
  }

  .business-filters {
    align-items: center;
    padding: 20px 40px;

    > div {
      margin-right: 20px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .star-rating-container {
    align-items: center;
    border: 2px solid $input-border-color;
    border-radius: $border-radius-lg;
    height: 46px;
    justify-content: center;
    padding: 0 20px;

    &.selected {
      border: 2px solid $secondary-brand-color;
      padding-right: 20px;

      .and-up {
        color: $darker-gray-text;
      }
    }

    .star-rating {
      align-items: center;
      flex-direction: row-reverse;
      justify-content: center;

      div {
        font-size: 0;
        height: 20px;
        margin-right: 5px;
        width: 20px;

        &:first-child {
          margin-right: 0;
        }

        &:not(.selected) {
          background: left / 20px 20px no-repeat url("../svg/gray-star.svg");
          cursor: pointer;
        }

        &.selected {
          background: left / 20px 20px no-repeat url("../svg/gold-star.svg");
        }
      }

      &:not(.no-hover) {
        &:hover {
          > .selected {
            background: left / 20px 20px no-repeat url("../svg/gray-star.svg");

            + div {
              &:hover {
                background: left / 20px 20px no-repeat url("../svg/gold-star.svg");
              }
            }
          }

          > div {
            &:hover {
              background: left / 20px 20px no-repeat url("../svg/gold-star.svg");

              ~ div {
                background: left / 20px 20px no-repeat url("../svg/gold-star.svg");
              }
            }
          }
        }
      }
    }

    .and-up {
      color: $input-border-color;
      margin-left: 5px;
    }
  }

  .pill-btn {
    display: flex;

    div {
      align-items: center;
      border: 2px solid $input-border-color;
      color: $input-border-color;
      cursor: pointer;
      display: flex;
      height: 46px;
      justify-content: center;
      width: 80px;

      &.selected {
        border: 2px solid $secondary-brand-color;
        color: $black;
      }

      &:not(.no-hover) {
        &:hover {
          color: $black;
        }
      }

      &:first-child {
        border-radius: $border-radius-lg 0 0 $border-radius-lg;
        border-right-width: 1px;
      }

      &:last-child {
        border-left-width: 1px;
        border-radius: 0 $border-radius-lg $border-radius-lg 0;
      }
    }
  }

  .browse-information {
    font-size: 1.125rem;
    justify-content: space-between;
    padding: 0 20px 20px;

    @include media-breakpoint-up(md) {
      padding: 0 40px 20px;
    }

    @include media-breakpoint-only(md) {
      flex-wrap: wrap;
    }
  }

  .pagination-breadcrumbs {
    @include el-light-border-bottom;

    margin-bottom: 20px;
    padding: 0 20px 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
      padding: 0 40px 40px;
    }
  }

  .browse-by-city {
    h2 {
      margin-bottom: 40px;
    }
  }

  .average-pricing-body-card {
    border-top: $primary-brand-color solid 8px;
    padding: 0;

    @include media-breakpoint-between(xs, md) {
      @include el-light-border-bottom;

      border-top: 0;
      padding: 0 20px;
    }

    .average-pricing-container {
      .average-pricing-text {
        @include el-light-border-bottom;

        padding: 40px 40px 0;

        @include media-breakpoint-between(xs, md) {
          border: 0;
          padding: 20px 0 0;
        }

        p {
          margin-bottom: 20px;

          @include media-breakpoint-between(xs, md) {
            margin-bottom: 0;
          }
        }
      }

      .average-pricing-items {
        justify-content: space-between;

        @include media-breakpoint-between(xs, md) {
          flex-wrap: wrap;
          padding-bottom: 40px;
        }

        .average-pricing-item {
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 20px 0 0;
          width: 100%;

          &:nth-child(2) {
            @include el-light-border-left;
            @include el-light-border-right;

            @include media-breakpoint-between(xs, md) {
              border: 0;
            }
          }

          @include media-breakpoint-up(md) {
            padding: 20px 40px;
            width: 365px;
          }

          @include media-breakpoint-only(md) {
            justify-content: center;
          }

          @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
          }

          .average-price-title {
            @include media-breakpoint-between(xs, md) {
              font-size: 1.125rem;
              text-transform: none;
            }

            @include media-breakpoint-only(md) {
              font-size: 0.875rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .business-faq-container {
    .community-faq-wrapper {
      border-top: 0;
      padding-top: 0;
    }
  }

  .unique-content {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 20px;
    }

    ol {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.recent-memorials {
  h2 {
    font-size: 1.5rem;
    font-weight: $font-weight-semibold;
    margin-bottom: 20px;
  }

  .description {
    margin-bottom: 20px;
  }

  .memorial-card {
    padding: 40px 0;
  }
}

.city-obituaries-container {
  .success-modal-container {
    left: 20px;
    position: fixed;
    right: 20px;
    top: 40px;

    @include media-breakpoint-up(md) {
      left: auto;
      right: 40px;
      top: 40px;
    }
  }

  .city-obituaries-ctas {
    .add-memorial-cta {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      h2 {
        font-size: 1.3125rem;
        font-weight: $font-weight-normal;
        margin-bottom: 10px;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .city-subscribe-cta {
      a {
        margin: 0; // TODO: Refactor small-button

        @include media-breakpoint-between(xs, md) {
          width: 100%;
        }
      }
    }
  }
}

.business-card--highlighted-label {
  background-color: $secondary-brand-color;
  height: 30px;

  span {
    color: $white;
    font-size: 0.875rem;
    left: 30px;
    position: relative;
    text-transform: uppercase;
    top: -14px;
  }
}

.business-card--highlighted-label-badge {
  left: 20px;
  position: relative;
}

.business-card {
  @include el-light-border-bottom;

  flex-direction: column;
  justify-content: space-between;
  padding: 40px;

  &:first-of-type {
    padding-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 40px;
  }

  &.business-card--highlighted {
    border-bottom: 1px solid currentcolor;

    &.thick {
      border-bottom: 4px solid currentcolor;
    }
  }

  @include media-breakpoint-between(xs, md) {
    padding: 40px 20px;

    &.business-card--highlighted {
      padding-top: 0;
    }
  }

  .business-card-primary-wrapper {
    justify-content: space-between;
  }

  .business-card-primary {
    @include media-breakpoint-between(xs, md) {
      flex-direction: column;
    }
  }

  .business-card-image {
    @include media-breakpoint-between(xs, md) {
      margin-bottom: 10px;
      min-height: 251.75px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }

    img {
      @include el-border;

      border-radius: $border-radius-sm;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 160px;
      }

      @include media-breakpoint-up(xl) {
        width: 240px;
      }
    }
  }

  .business-card-info {
    min-height: 180.5px;

    @include media-breakpoint-between(xs, md) {
      min-height: 140px;
    }

    .business-card-name {
      margin-bottom: 10px;
    }

    .business-card-address {
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }
    }

    .business-card-rating {
      .ratings-container {
        height: 20px;
        position: relative;

        .ratings-inner-container-grey,
        .ratings-inner-container-gold {
          position: absolute;

          .ratings-wrapper {
            .ratings-bottom,
            .ratings-top {
              background-image: url("../svg/gray-star.svg");
              height: 20px;
              margin-right: 5px;
              width: 20px;
            }

            .ratings-top {
              background-image: url("../svg/gold-star.svg");
            }
          }
        }
      }
    }

    .business-card-rating-link {
      margin-bottom: 0;
      margin-right: 10px;
    }

    .business-card-price {
      align-content: center;
      margin-bottom: 0;
      margin-top: 10px;

      .light-gray-label {
        align-items: center;
      }

      .business-price {
        margin-left: 10px;
      }
    }
  }

  .review-container {
    .user-icon {
      align-items: flex-start;
      padding-top: 20px;

      @include media-breakpoint-between(xs, md) {
        padding-top: 25px;

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .review-content {
      margin-top: 20px;
      padding-left: 10px;

      span {
        color: $primary-brand-color;
        font-weight: $font-weight-normal;
      }
    }
  }
}

.business-price {
  .grey-dollar-signs {
    color: $medium-gray;
  }
}

.business-faq-container {
  .staff-faq-wrapper {
    @include el-border-top;

    padding-top: 40px;
  }

  h4 {
    margin-bottom: 10px;

    @include media-breakpoint-between(xs, md) {
      font-size: 1.3125rem;
    }
  }

  ul {
    list-style-image: url("../svg/nav-arrow-blue.svg");
    margin: 0;
    padding-left: 30px;
  }

  li {
    margin-bottom: 40px;
  }

  .faq-question-text {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }
  }

  .faq-answer-text {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .phone-call-cta {
    align-items: flex-start;
    font-size: 1.3125rem;
    font-weight: $font-weight-semibold;
    margin-bottom: 40px;

    img {
      margin: 8px 10px 0 0;
    }
  }

  .community-faq-wrapper {
    @include el-border-top;

    padding-top: 40px;
  }

  .community-faq-container {
    .faq-bullet-wrapper {
      align-items: flex-start;

      .nav-arrow-bullet {
        margin: 5px 15px 0 5px;
        min-width: 21px;
      }

      .faq-nav-bullet-content-wrapper {
        min-width: 0;
      }
    }

    .faq-non-bullet-content {
      @include media-breakpoint-up(md) {
        margin-left: 40px;
      }
    }

    .user-post-form {
      margin-bottom: 40px;
    }

    .expandable-faq-answers {
      .faq-answer {
        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    }

    .faq-answer-expander,
    .faq-answer-collapser {
      color: $primary-brand-color;
      cursor: pointer;
      font-weight: $font-weight-semibold;
      margin: 0 0 40px 40px;
      white-space: nowrap;

      @include media-breakpoint-between(xs, md) {
        margin-left: 80px;
      }

      img {
        margin-left: 10px;
        min-width: 18px;
      }
    }

    .answer-text {
      border-radius: 3px;
      height: 80px;
    }

    .collapsed-submit-wrapper {
      .collapsed-submit-btn {
        button {
          margin-bottom: 20px;
        }
      }
    }

    .g-recaptcha-disclaimer {
      width: 335px;

      @include media-breakpoint-between(xs, md) {
        width: auto;
      }
    }
  }

  .community-faq-answer-success-modal {
    position: fixed;
  }
}

.business-container {
  .page-header {
    justify-content: space-between;

    @include media-breakpoint-between(xs, lg) {
      display: flex;
      flex-wrap: wrap;
    }

    .breadcrumbs {
      ol {
        margin-bottom: 0;
      }
    }

    .edit-cta {
      flex-shrink: 0;

      @include media-breakpoint-between(xs, lg) {
        margin-top: 20px;
      }

      a {
        color: $secondary-brand-color;
      }
    }
  }

  .body-card {
    padding: 20px 0;

    &.floating-promo-cta {
      padding: 16px 26px 16px 16px;
    }

    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }
  }
}

.business-top-wrapper {
  align-content: flex-end;
  flex-direction: column;

  .business-top-content {
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    .business-image-and-map-container {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 640px;
      }

      @include media-breakpoint-up(lg) {
        width: 560px;
      }
    }

    h1 {
      @include media-breakpoint-between(xs, md) {
        padding: 0 20px;
      }
    }

    .business-image-container {
      @include media-breakpoint-between(xs, md) {
        padding: 0;
      }
    }
  }

  .business-image-container {
    @include el-border;

    align-items: flex-start;
    border-radius: 5px;

    @include media-breakpoint-between(xs, md) {
      border-left: 0;
      border-radius: 0;
      border-right: 0;
    }
  }

  .business-image {
    width: 50%;

    @include media-breakpoint-up(md) {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      height: 245px;
      width: 319px;
    }

    @include media-breakpoint-up(lg) {
      height: 210px;
      width: 279px;
    }
  }

  .business-map-container {
    width: 50%;
  }

  .business-map {
    @include media-breakpoint-between(xs, lg) {
      height: 0;
      padding-bottom: math.percentage(math.div(108.63, 186.5));
      position: relative;
      width: 100%;
    }

    @include media-breakpoint-only(md) {
      height: 197px;
      width: 319px;
    }

    @include media-breakpoint-up(lg) {
      height: 162px;
      width: 279px;
    }

    &.no-directions {
      @include media-breakpoint-between(xs, md) {
        height: 0;
        padding-bottom: math.percentage(math.div(140.45, 186.5));
        position: relative;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        height: 245px;
      }

      @include media-breakpoint-up(lg) {
        height: 210px;
      }
    }

    iframe {
      border-top-right-radius: 4px;
      height: 100%;
      width: 100%;

      @include media-breakpoint-between(xs, md) {
        border-radius: 0;
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }

  .business-directions {
    align-items: center;
    background-color: $very-light-gray;
    border-bottom-right-radius: 4px;
    height: 48px;
    justify-content: center;

    @include media-breakpoint-between(xs, md) {
      border-radius: 0;
      height: 0;
      padding-top: math.percentage(math.div(32, 186.5));

      a {
        font-size: 14px;
        margin-top: -17%;
      }
    }

    img {
      margin-right: 10px;
      margin-top: -4px;
    }
  }
}

.business-navigation-container {
  @include el-border-bottom;

  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 40px 0;
  }
}

.business-body-container {
  justify-content: space-between;
  padding-top: 40px;

  @include media-breakpoint-between(xs, lg) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    padding: 40px 40px 0;
  }

  h1 {
    font-size: 2.25rem;
    margin-bottom: 20px;
  }

  .business-primary {
    flex-shrink: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 560px;
    }

    @include media-breakpoint-between(xs, md) {
      > :not(.business-image-container) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .business-details-container {
      grid-template-columns: 1fr;

      @include media-breakpoint-up(md) {
        grid-column-gap: 1rem;
        grid-template-columns: 1fr 1fr;
      }
    }

    .business-details-item {
      align-items: flex-start;
      margin-bottom: 40px;

      @include media-breakpoint-between(xs, md) {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.business-overview {
        width: 100%;

        p {
          &:last-of-type {
            margin-bottom: 10px;
          }
        }
      }

      img {
        margin-right: 20px;
        padding-top: 24px;

        &.business-details-address-icon {
          margin-right: 26px;
          min-width: 14px;
        }
      }

      .light-gray-label {
        color: $light-gray-text;
        margin-bottom: 0;
      }
    }

    .price-list {
      @include el-border-top;

      margin-bottom: 40px;
      padding-top: 40px;
    }

    .pricing-note {
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 560px;
      }

      h3 {
        font-size: 1.3125rem;
        font-weight: $font-weight-semibold;
      }
    }

    .leave-review {
      @include el-border-top;

      margin-bottom: 40px;
      padding-top: 40px;

      h2 {
        margin-bottom: 40px;
      }

      .el-input-group {
        &:last-of-type {
          @include media-breakpoint-between(xs, md) {
            margin-bottom: 20px;
          }
        }
      }

      .btn-form-submit {
        padding: 0;

        .btn {
          @include media-breakpoint-between(xs, md) {
            width: 100%;
          }
        }
      }
    }
  }

  .business-question-module {
    .question-form {
      width: 100%;
    }

    .question-module-header {
      margin-bottom: 8px;
    }

    .el-input-group {
      margin-bottom: 10px;
    }

    textarea {
      height: 120px;
    }

    .borderless-input {
      width: 100%;
    }

    .btn {
      color: $white;
      max-width: 335px;
      width: 100%;
    }

    .privacy-note {
      margin: 10px 0 20px;
    }
  }

  .send-question-success-modal {
    position: absolute;

    @include media-breakpoint-between(xs, md) {
      right: 20px;
    }

    .success-modal {
      @include media-breakpoint-between(xs, md) {
        padding-left: 20px;
        text-align: left;
      }
    }
  }

  .business-sidebar {
    margin-left: 40px;
    max-width: 375px;
    width: 100%;

    @include media-breakpoint-between(xs, lg) {
      display: none;
    }

    .business-sidebar-content {
      position: sticky;
      top: 60px;

      @include media-breakpoint-up(lg) {
        top: 80px;
      }

      &.has-floating-promo-cta {
        top: 140px;
      }

      hr {
        margin: 20px 0;

        @include media-breakpoint-up(xl) {
          margin: 40px 0;
        }
      }

      h2 {
        @include media-breakpoint-between(xs, xl) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .business-create-memorial-cta {
    @include el-light-border-top;

    padding-top: 40px;

    p {
      margin-bottom: 20px;
    }

    .cta-link-content {
      @include media-breakpoint-between(xs, lg) {
        flex-direction: row-reverse;
      }
    }

    .cta-link-text {
      min-width: 291px;
    }

    .image-wrapper {
      margin-right: 20px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        margin-left: 20px;
        margin-right: 0;
      }
    }

    .cta-link-wrapper {
      color: $dark-gray-text;
      font-weight: $font-weight-normal;
    }

    .link-secondary {
      align-items: center;
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        margin-top: 40px;
      }
    }
  }
}

.business-reviews-container {
  @include el-border-top;

  margin-bottom: 40px;
  padding-top: 40px;

  h2 {
    font-size: 1.5rem;
    font-weight: $font-weight-semibold;
    margin-bottom: 40px;
  }

  .business-review {
    @include el-border-top;

    margin-bottom: 40px;
    padding-top: 40px;

    &::before {
      content: " ";
      display: block;
      height: 100px;
      margin-top: -100px;
      pointer-events: none;
      visibility: hidden;
    }

    &:first-of-type {
      border-top: 0;
      padding-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .review-rating {
      margin-bottom: 10px;
    }

    .review-text {
      font-size: 1.3125rem;
      letter-spacing: 0.22px;
      line-height: 31.5px;
    }

    .user-post-container {
      margin-top: 20px;
    }
  }
}

.business-matches-container {
  &.related-businesses-container {
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 40px;
    }

    h2 {
      margin-bottom: 0;

      @include media-breakpoint-between(xs, md) {
        @include el-border-top;

        font-size: 1.5rem;
        font-weight: $font-weight-semibold;
        padding: 40px 20px 0;
      }

      @include media-breakpoint-only(md) {
        padding: 40px 0 0 40px;
      }
    }
  }

  .business-matches {
    justify-content: space-between;
    padding: 40px;

    &.related-businesses {
      padding: 0 0 40px;

      @include media-breakpoint-up(md) {
        padding: 20px 0 40px;
      }
    }

    @include media-breakpoint-between(xs, lg) {
      flex-direction: column;
      padding: 0;

      &.related-businesses {
        margin-top: 0;
      }
    }

    @include media-breakpoint-between(xs, md) {
      flex-wrap: wrap;
    }
  }

  hr {
    margin: 0 auto;
    width: calc(100% - 80px);

    @include media-breakpoint-between(xs, md) {
      width: calc(100% - 40px);
    }

    &:first-of-type {
      display: none;
    }
  }

  .business-match-container {
    width: 100%;

    @include media-breakpoint-between(xs, md) {
      padding: 40px 20px;
    }

    @include media-breakpoint-only(md) {
      padding: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
      width: 312px;

      &:last-child {
        margin-right: 0;
      }
    }

    .business-match {
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-only(md) {
        flex-direction: row;
      }
    }

    .hovered {
      cursor: pointer;
      opacity: 0.1;
      transition: opacity 0.5s ease;
    }

    .unhovered {
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    .business-image-wrapper {
      min-width: 184px;

      @include media-breakpoint-up(lg) {
        min-width: 0;
        width: 100%;
      }

      .business-image {
        @include media-breakpoint-between(xs, md) {
          border-radius: $border-radius-sm;
        }
      }
    }

    .business-image {
      @include el-border;
      @include background-img-aspect-ratio(335px, 181px);

      background-size: cover;

      @include media-breakpoint-up(md) {
        @include background-img-aspect-ratio(312px, 232px);
      }
    }

    .business-info {
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      padding-top: 10px;

      @include media-breakpoint-between(xs, md) {
        flex: 0 auto;
      }

      @include media-breakpoint-up(md) {
        flex: 1 0 0;
        margin-left: 20px;
        padding-top: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 20px;

        &.business-planning-results-info {
          min-height: 312px;
        }
      }

      .name-address-price-wrapper {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-between(xs, md) {
          display: block;
        }

        .dark-gray-label {
          display: none;

          @include media-breakpoint-between(xs, md) {
            display: block;
            margin-top: 20px;
          }
        }

        &.related-business-name-address-price-wrapper {
          .light-gray-label {
            @include media-breakpoint-only(md) {
              margin-top: 3px;
            }
          }
        }

        .business-price {
          @include media-breakpoint-up(md) {
            margin-left: 40px;
          }
        }

        .city-ratings {
          @include media-breakpoint-between(xs, md) {
            flex-direction: column;
          }

          @include media-breakpoint-only(md) {
            margin-top: 3px;
          }
        }

        .light-gray-label {
          margin: 0 10px 10px 0;
        }
      }

      .business-address {
        margin-top: 10px;
      }

      .city-ratings {
        flex-direction: column;
      }

      .ratings-container {
        height: 20px;
        position: relative;

        .ratings-inner-container-grey,
        .ratings-inner-container-gold {
          position: absolute;

          .ratings-wrapper {
            .ratings-bottom,
            .ratings-top {
              background-image: url("../svg/gray-star.svg");
              height: 20px;
              margin-right: 5px;
              width: 20px;
            }

            .ratings-top {
              background-image: url("../svg/gold-star.svg");
            }
          }
        }
      }

      .price-review-container {
        padding-top: 20px;
        width: 100%;

        .related-price {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-only(md) {
            flex-direction: row;
          }

          .business-price {
            line-height: 1.5;
            margin-left: 0;
            margin-right: 40px;

            @include media-breakpoint-up(lg) {
              line-height: 1;
              margin-right: 20px;
            }
          }

          .dark-gray-label {
            display: block;
            margin-right: 10px;
          }
        }
      }

      .business-ctas {
        @include media-breakpoint-between(xs, lg) {
          display: flex;
          margin-top: 10px;
        }

        .light-gray-label {
          padding-top: 20px;
        }

        .contact-btns {
          @include media-breakpoint-between(xs, lg) {
            flex-wrap: nowrap;
          }

          .call-btn {
            @include media-breakpoint-between(xs, md) {
              order: 0;
            }

            @include media-breakpoint-only(md) {
              order: -1;
            }
          }
        }

        .match-cta-btn {
          cursor: pointer;
          letter-spacing: 0.25px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          @include media-breakpoint-between(xs, lg) {
            align-items: center;
            border: 1px solid $secondary-brand-color;
            border-radius: $border-radius-lg;
            color: $secondary-brand-color;
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            font-size: 1.125rem;
            height: 106px;
            justify-content: center;
            line-height: 1.2;
            margin-right: 10px;
            text-align: center;
            width: calc(33% - 6.33px);

            &:nth-child(2) {
              margin-right: 10px;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          @include media-breakpoint-only(md) {
            font-size: 1.125rem;

            &:nth-child(2) {
              margin-right: 0;
            }

            &:last-child {
              margin-right: 20px;
            }
          }
        }

        .business-price {
          .business-price {
            display: inline-block;
          }

          .price-btn {
            margin: 0 20px 0 0;

            @include media-breakpoint-up(lg) {
              margin: 0 0 0 20px;
            }
          }
        }

        .business-contact-ctas {
          min-width: 100%;

          @include media-breakpoint-between(xs, md) {
            margin-top: 40px;
          }

          @include media-breakpoint-up(lg) {
            min-width: auto;
          }
        }
      }
    }

    &.related-business-container {
      @include media-breakpoint-between(xs, md) {
        padding: 40px 20px !important;
      }

      @include media-breakpoint-only(md) {
        padding: 40px !important;
      }
    }
  }

  .expand-match-contact {
    background-color: $light-blue;
    border: 1px solid $medium-blue;
    color: $dark-blue;
    font-size: 1.3125rem;
    margin: 40px 0 0;
    padding: 20px 15px;

    @include media-breakpoint-up(lg) {
      margin: 0 40px 40px;
    }

    &.question-expand {
      background-color: transparent;
      border: 0;
      color: $dark-gray-text;
      font-size: 1.125rem;
      margin: 0;
      padding: 0;
    }

    .contact-header {
      font-weight: $font-weight-semibold;
      margin-bottom: 10px;
    }

    .contact-phone-number {
      white-space: nowrap;
    }

    p,
    a {
      color: $dark-blue;
      font-weight: 300;
    }

    .business-question-wrapper {
      padding: 40px 0 0;

      @include media-breakpoint-up(lg) {
        padding: 0 40px 40px;
      }

      .question-form {
        width: 100%;
      }

      .btn-secondary {
        min-width: 335px;
      }
    }
  }

  .price-list-container {
    @include el-border-top;

    margin-top: 40px;
    padding: 20px;
    position: relative;
    scroll-behavior: smooth;

    @include media-breakpoint-between(xs, lg) {
      background: $white;
      height: 0%;
      left: 0;
      margin-top: 0;
      opacity: 0;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      transition:
        opacity 0.35s,
        visibility 0.35s,
        height 0.35s;
      visibility: hidden;
      width: 100%;
      z-index: $zindex-modal;

      &.open {
        height: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 40px;
    }

    .pricing-section-arrow {
      @include el-border-top;
      @include el-border-left;

      background-color: $white;
      height: 36px;
      margin-left: -18px;
      margin-top: -59px;
      position: absolute;
      transform: rotate(45deg);
      width: 36px;
    }

    .close-overlay {
      cursor: pointer;
      position: absolute;
      right: 20px;

      @include media-breakpoint-up(md) {
        right: 40px;
      }
    }

    .price-list {
      h2 {
        @include media-breakpoint-between(xs, lg) {
          font-size: 2rem;
        }
      }

      .price-list-service-filters {
        height: 328px;

        @include media-breakpoint-up(md) {
          height: 162px;
        }

        @include media-breakpoint-up(xl) {
          height: auto;
        }

        .custom-select-with-caret {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: calc(50% - 10px);
          }

          @include media-breakpoint-up(xl) {
            width: 243px;
          }
        }
      }
    }

    .price-list-scroll-top {
      cursor: pointer;
      margin-top: 40px;
      text-align: center;
    }

    &.open {
      h2 {
        font-size: 1.75rem;
        font-weight: $font-weight-semibold;
        line-height: 1.5;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }
      }
    }
  }

  @include media-breakpoint-between(xs, lg) {
    padding: 0 !important;
  }

  .browse-cities-in-state {
    @include media-breakpoint-between(xs, md) {
      padding: 0 0 20px 20px;
    }

    @include media-breakpoint-only(md) {
      padding: 0 0 40px 40px;
    }
  }
}

.business-q-a-wrapper {
  @include el-light-border-top;

  margin-bottom: 40px;
  padding-top: 20px;

  .business-question {
    font-size: 0.975rem;
    margin-top: 20px;

    .question-container {
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      img {
        margin: 0 10px 0 0;

        @include media-breakpoint-up(md) {
          margin: 0 20px;
        }
      }

      .text {
        font-weight: $font-weight-semibold;
      }
    }

    .answer-container {
      margin: 5px 10px 0;

      @include media-breakpoint-up(md) {
        margin: 10px 40px 0;
      }

      .answer-meta {
        .name {
          font-weight: $font-weight-semibold;
        }

        .date {
          color: $lighter-gray-text;
          font-style: italic;
        }
      }

      .answer-meta--data {
        margin-right: 20px;
      }

      .answer {
        flex-grow: 1;

        .edit-answer-link {
          font-size: 0.9375rem;

          img {
            height: 0.9375rem;
            width: auto;
          }
        }

        .form {
          textarea {
            height: 80px;
          }

          .answer-submit-container {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            @include media-breakpoint-between(xs, md) {
              flex-direction: column-reverse;
            }

            .btn {
              margin-bottom: 0;

              @include media-breakpoint-between(xs, md) {
                margin-bottom: 6px;
                width: 100%;
              }
            }

            .saved {
              color: $lighter-gray-text;
              margin-right: 10px;

              @include media-breakpoint-between(xs, md) {
                margin-right: 0;
              }
            }
          }
        }
      }

      &.unanswered {
        .answer {
          .answer-meta {
            display: none !important;
          }

          .text {
            color: $lighter-gray-text;
            font-style: italic;
          }
        }
      }
    }
  }
}

.review-response {
  .response-label {
    padding: 8px 0;
  }

  .user-post-edit-control {
    &.edit-confirmation {
      bottom: 0;
    }
  }
}

// TODO: Should this down to .affiliation-photo-memory be in a memorials CSS file?
.affiliations {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.selected {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.has-photo-form-expanded {
    > .d-flex {
      align-items: flex-start;
    }
  }

  .btn {
    margin: 0 0 20px;
    padding: 14px 20px;
  }

  .cancel {
    color: $darker-gray;
    float: right;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  .add-affiliation {
    @include media-breakpoint-between(xs, md) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.add-affiliation-container {
  > div {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 425px;
    }
  }

  .borderless-input {
    min-width: auto;
    width: 100%;
  }
}

.community-organization-search {
  .clear-radios {
    color: $darker-gray;
    font-size: 0.875rem;
    margin-left: 10px;
    text-transform: uppercase;

    &:hover {
      color: $darker-gray;
    }
  }

  .create-organization {
    .no-website-checkbox-container {
      > div,
      label {
        margin-top: 10px;
      }

      > label {
        @include media-breakpoint-between(xs, md) {
          font-size: 1rem;
        }
      }
    }

    .no-website-checkbox-label {
      margin-bottom: 0;
    }
  }
}

.inline-radio-label-field {
  margin-left: 10px;
}

.affiliation-type-input-group {
  width: 100%;

  &.invisible {
    height: 1px;
    opacity: 0;
    overflow-y: hidden;
  }
}

.affiliation {
  border-radius: $border-radius-lg;
  box-shadow: $card-shadow;
  padding: 20px;

  &.has-photo {
    padding-bottom: 0;
  }

  h3,
  h4 {
    margin-bottom: 4px;
  }

  .btn-link {
    margin: 3px 0;
    min-width: auto;
    padding: 0;

    &.add-photo-memory {
      margin-right: 8px;
    }

    img {
      margin-bottom: 2px;
    }
  }
}

.affiliation-photo-memory-form {
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  margin: 20px 0;
  padding: 20px 0;

  .borderless-input {
    width: 100%;
  }

  .photo-input {
    margin-top: 20px;
  }
}

.affiliation-photo-memory {
  margin: 20px 0 0;

  .caption {
    color: $light-gray-text;
    margin: 20px 0;
  }

  .memory-photo-container {
    height: calc(75vw);
    margin: 0 -20px;
    padding-top: 75%;
    position: relative;
    width: calc(75vw);

    @include media-breakpoint-up(md) {
      height: 0;
      width: calc(100% + 40px);
    }

    .memory-photo {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;

      &.at-bottom {
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
      }
    }
  }
}

.profile-section-details {
  .community-involvement-category {
    flex: 0 0 100%;

    &.single {
      flex: 0 0 calc(50% - 20px);

      @include media-breakpoint-between(xs, md) {
        flex: 0 0 calc(100% - 10px);
      }

      .mason-flex-container {
        > div {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .mason-flex-container {
    margin-bottom: 20px;
  }
}

.answer-submit-container {
  .error {
    color: $red;
    margin-right: 10px;
  }
}

.column-section__in-top-content {
  margin-left: 20px;
  max-width: 335px;
  padding: 0;
  width: 100%;
}

.column-section__business {
  @include media-breakpoint-between(xs, lg) {
    padding-left: 0;
  }

  &:first-of-type {
    @include media-breakpoint-between(xs, lg) {
      padding-top: 0;
    }
  }

  &.column-section--border-top {
    border-bottom: 0;
    border-top: 1px solid $medium-gray;
  }
}

.mobile-drawer-backdrop {
  background-color: $black-with-85-percent-opacity;
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: $zindex-modal-backdrop;
}

.mobile-drawer__trigger-container--business {
  @include el-border-top;

  bottom: 0;
  box-shadow: -1px -1px 3px 0 $purple-with-10-percent-opacity;
  padding: 10px 20px 20px;
  z-index: $zindex-modal;

  .caret {
    appearance: none;
    display: none;
    left: -100%;
    margin-bottom: 10px;
    position: absolute;
    top: -100%;

    &:checked {
      ~ label {
        background-image: url("../svg/caret-down.svg");
      }

      ~ .mobile-drawer__content-container {
        max-height: 423px;
        overflow: visible;
      }
    }

    ~ label {
      background-image: url("../svg/caret-up.svg");
      background-repeat: no-repeat;
      height: 12px;
      margin-bottom: 0;
      width: 20px;
    }
  }
}

.mobile-drawer__content-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

  .column-section {
    border-bottom: 0;
    padding: 15px;

    .column-section__header {
      justify-content: center;
    }
  }
}

.edit-business-arrow-header {
  align-items: center;
  margin: 40px 0 20px;

  h3 {
    margin: 0 0 0 10px;
  }
}

.price-edit-form,
.price-edit-form-goods {
  border-bottom: 0.5px solid $black-with-20-percent-opacity;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 617px;
  }

  .row-wrapper {
    border-bottom: 1px solid $black-with-20-percent-opacity;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-between(xs, md) {
      padding: 20px 0;
    }

    @include media-breakpoint-up(md) {
      border: 0.5px solid $black-with-20-percent-opacity;
      border-bottom: 0;
    }

    .row-detail {
      align-items: center;

      @include media-breakpoint-between(xs, md) {
        flex-direction: row;
      }

      @include media-breakpoint-up(md) {
        padding: 20px;
      }
    }

    .row-price {
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      @include media-breakpoint-up(md) {
        border-left: 0.5px solid $black-with-20-percent-opacity;
        padding: 20px;
        width: 177px;
      }

      &.row-price-wide {
        @include media-breakpoint-between(xs, md) {
          flex-direction: row;
          justify-content: flex-start;
        }

        @include media-breakpoint-up(md) {
          width: 150px;
        }

        @include media-breakpoint-up(lg) {
          width: 250px;
        }

        label {
          margin-bottom: 0;
        }
      }

      input {
        border: 0.5px solid $black-with-20-percent-opacity;
        border-radius: $border-radius-sm;
        margin-left: 2px;
        text-align: center;
        width: 100%;

        &::placeholder {
          color: $lighter-gray-text;
        }
      }
    }
  }
}

.price-edit-form {
  .row-wrapper {
    .row-price {
      input {
        @include media-breakpoint-between(xs, md) {
          width: 110px;
        }
      }
    }
  }
}

.pricing-errors {
  .errorlist {
    margin-bottom: 0;
    padding-top: 10;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }
}

.price-edit-form-goods {
  .row-detail {
    flex: 1;
  }

  .row-wrapper {
    .row-detail {
      @include media-breakpoint-only(md) {
        width: 25%;
      }
    }

    .edit-price-range-wrapper {
      @include media-breakpoint-only(md) {
        width: 50%;
      }
    }

    > .row-price {
      @include media-breakpoint-only(md) {
        width: 25%;
      }
    }

    &.row-header {
      border: 0;

      .row-detail {
        padding: 0 0 20px;
      }

      .row-price {
        border: 0;
        padding-bottom: 10px;
        text-align: center;
      }
    }
  }

  @include media-breakpoint-between(xs, md) {
    width: 100%;

    .row-wrapper {
      flex-flow: column wrap;

      .row-detail {
        width: 100%;
      }

      .row-price {
        flex-direction: column;
        width: 100%;
      }

      .edit-price-range-wrapper {
        .mobile-price-header {
          font-size: 0.875rem;
          margin-left: 10px;
        }

        .mdash-price-range {
          align-items: flex-end;
          display: flex;
          margin: 0 10px 14px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    width: 617px;
  }

  @include media-breakpoint-up(lg) {
    width: 794px;
  }
}

.price-edit-form-goods-full-width {
  width: 100%;

  &:nth-last-child(2) {
    margin-bottom: 40px;
  }

  .row-wrapper {
    @include media-breakpoint-between(xs, md) {
      flex-flow: row wrap;
    }
  }

  .row-detail-non-adult {
    > div {
      padding-left: 28px;
    }
  }

  .errorlist {
    margin-bottom: 0;
    padding-top: 0 !important;
  }
}

.pricing-note {
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 617px;
  }

  @include media-breakpoint-up(lg) {
    width: 794px;
  }
}

.resources-welcome-banner {
  border-bottom: 4px solid $white;
  box-shadow: $card-shadow;
  z-index: $zindex-sticky;

  .banner-content {
    color: $white;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 1095px;
    }

    p {
      font-size: 0.9375rem;

      @include media-breakpoint-up(md) {
        flex-basis: 50%;
      }
    }

    .btn-copy {
      background-color: $white !important;
    }
  }
}

// TODO: Consider moving this out of places
.business-user-guidance-container {
  .business-user-guidance {
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
  }

  .choice-buttons {
    margin-bottom: 20px;
  }

  [type="submit"] {
    margin: 0 0 20px;
    width: 100%;
  }

  .exit-popup {
    position: relative;
  }

  .working-overlay {
    background-color: $white;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: fixed;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      position: absolute;
    }
  }

  .business-user-guidance-resources {
    .business-user-guidance-resource {
      margin-bottom: 20px;
    }
  }

  .start-over {
    color: $light-gray-text;
    font-size: 0.75rem;

    img {
      height: 12px;
      width: 12px;
    }
  }

  .card-img-cta {
    flex: 0 0 calc(40% - 1rem);
    height: 0;
    padding-top: 28%;
    position: relative;
    width: calc(40% - 1rem);

    iframe,
    img {
      border: 1px solid $border-color;
      border-radius: $border-radius-lg;
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: top left;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .business-contact-info {
    list-style: none;
    padding: 0;

    li {
      display: flex;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.body-card {
  &.floating-promo-cta {
    border: 1px solid var(--secondary-brand-color);
    box-shadow: 0 0 8px -3px var(--secondary-brand-color);
    font-size: 1rem;
    padding: 16px 26px 16px 16px;
    text-align: center;
    transition:
      box-shadow 300ms ease-in-out,
      transform 300ms ease-in-out;
    z-index: $zindex-sticky;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      text-align: start;
    }

    .floating-promo-cta-link {
      font-size: 1.125rem;
      margin-left: 5px;
      margin-top: 5px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &:hover {
      box-shadow: 0 1px 10px 0 var(--secondary-brand-color);
      transform: translateY(-2px);
    }
  }
}

.donations-list {
  .alert {
    margin-top: 20px;
  }

  .page-header {
    @include media-breakpoint-between(xs, md) {
      @include el-border-bottom;

      padding-bottom: 20px;
    }

    .donations-tools {
      .download-link {
        align-self: center;
        margin: 10px 0;

        @include media-breakpoint-up(xl) {
          align-self: flex-end;
          margin: 0 20px 4px;
        }

        img {
          margin-bottom: 4px;
        }
      }

      .donations-filter-controls {
        .dark-gray-label {
          text-align: left;

          @include media-breakpoint-up(md) {
            text-align: right;
          }
        }

        .control-container {
          .divider {
            margin: 0 10px;

            @include media-breakpoint-between(xs, md) {
              display: none;
            }
          }

          .el-input-group-custom-select {
            @include media-breakpoint-between(xs, md) {
              flex-grow: 1;
              margin: 0 0 10px;
            }

            .very-small-select {
              padding-left: 0;
              padding-right: 0;

              @include media-breakpoint-between(xs, md) {
                width: 100%;
              }

              select {
                padding-left: 20px;
                padding-right: 50px;
              }
            }
          }
        }
      }
    }
  }

  .body-card {
    padding: 0;

    table {
      border-bottom: 0;
      border-left: 0;
      border-radius: $border-radius-lg;
      border-right: 0;
      border-top: 0;
      font-size: 0.9375rem;
      margin-bottom: 0;

      thead {
        th {
          border: 1px solid $table-border-gray;
          border-top: 0;

          &:first-child {
            border-top-left-radius: 8px;

            @include media-breakpoint-up(md) {
              border-left: 0;
            }
          }

          &:last-child {
            border-top-right-radius: 8px;

            @include media-breakpoint-up(md) {
              border-right: 0;
            }
          }
        }
      }

      tbody {
        td {
          border: 1px solid $table-border-gray;

          a {
            img {
              height: 11px;
              margin-bottom: 3px;
              width: 13px;
            }
          }

          &.amount-cell {
            font-family: monospace;
            text-align: right;

            &.refund {
              color: $red;
            }
          }

          &:first-child {
            border-left: 1px solid $table-border-gray;
            white-space: nowrap;

            @include media-breakpoint-up(md) {
              border-left: 0;
            }
          }

          &:last-child {
            border-right: 1px solid $table-border-gray;

            @include media-breakpoint-up(md) {
              border-right: 0;
            }
          }
        }

        > :last-child {
          td {
            border-bottom: 1px solid $table-border-gray;

            @include media-breakpoint-up(md) {
              border-bottom: 0;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }

  .paginator-note {
    margin: 10px 0 20px;

    @include media-breakpoint-up(md) {
      margin: 10px 0 0;
    }
  }
}

.search-business-wrapper {
  &.body-card {
    @include light-gray-border-bottom;

    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
    }
  }

  .el-radio-btn {
    margin: 20px 0 0;
  }

  .edit-disposition-method {
    margin: 0 0 4px 20px;

    &:hover {
      color: $light-gray-text !important;
    }

    img {
      margin-right: 4px;
    }
  }

  .el-input-group {
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 40px;

    @include media-breakpoint-up(lg) {
      align-items: flex-end;
      flex-direction: row;
    }

    &:last-of-type {
      padding-bottom: 20px;
    }

    .borderless-input {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 300px;
      }

      @include media-breakpoint-up(lg) {
        width: 472px;
      }
    }
  }

  label {
    margin-bottom: 0;
  }

  .field-divider {
    margin: 0 40px 0 0;

    @include media-breakpoint-up(lg) {
      margin: 0 40px;
    }
  }

  .el-checkbox-wrapper {
    margin-top: 20px;
  }

  .el-checkbox {
    margin-top: -3px;
  }

  .custom-select-with-caret {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    select {
      font-weight: $font-weight-normal;
    }
  }

  .disabled {
    background-color: $light-gray-with-90-percent-opacity;
  }
}

.employee-form-row {
  &.hidden {
    border: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
}
