.hospice-resources-container {
  .page-header {
    margin-bottom: 40px;
  }

  .callout {
    align-items: center;
    background-color: $light-blue;
    border: 1px 0 solid $medium-blue;
    display: flex;
    margin-bottom: 40px;
    padding: 10px 20px;

    @include media-breakpoint-up(md) {
      border: 1px solid $medium-blue;
      border-radius: $border-radius-lg;
      padding: 20px;
    }

    a {
      color: $secondary-brand-color;

      img {
        margin-top: -3px;
      }
    }

    div {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .hospice-resource-cards {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    place-content: space-between center;

    @include media-breakpoint-up(md) {
      height: 833px;
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      justify-content: space-around;
    }

    @include media-breakpoint-up(xl) {
      height: 542px;
      justify-content: space-between;
    }

    .hospice-resource-card {
      align-items: flex-end;
      background-size: cover;
      border-radius: $border-radius-lg;
      display: flex;
      height: 251px;
      width: 335px;

      @include media-breakpoint-between(xs, md) {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      > div {
        background-color: $lighter-gray-text;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: $white;
        opacity: 0.86;
        padding: 10px;

        h2 {
          font-size: 1.25rem;
          line-height: 1rem;
          margin-bottom: 10px;
        }

        div {
          font-weight: $font-weight-normal;
          line-height: 1.5rem;
        }
      }
    }
  }
}
