.cemetery-container {
  .price-list {
    .price-list-service-filters {
      height: 152px;

      @include media-breakpoint-up(md) {
        height: 71px;
      }

      .custom-select-with-caret {
        @include media-breakpoint-between(xs, md) {
          width: 100%;
        }
      }

      .price-filter {
        cursor: pointer;
      }
    }

    .price-list-table {
      td {
        &.pricing-secondary-cell {
          width: 120px;

          @include media-breakpoint-up(md) {
            width: 218px;
          }
        }
      }
    }
  }
}
