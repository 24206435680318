@use "sass:map";

.business-edit-container {
  .business-edit-nav-container {
    border-bottom: 1px solid $input-border-color;
  }

  .business-edit-nav-tabs {
    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }

    a {
      font-size: 1.3125rem;

      @include media-breakpoint-only(md) {
        font-size: 1.125rem;
      }
    }
  }

  .nav-submit {
    cursor: pointer;
  }

  .edit-business-container {
    .errorlist {
      padding-top: 10px;
    }
  }

  h1 {
    font-size: 1.3125rem;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      font-weight: $font-weight-normal;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.25rem;
    }
  }

  .borderless-input {
    width: 100%;
  }

  .btn-lg {
    width: $el-button-length;

    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }
  }

  .edit-faq-container {
    .edit-faq-form {
      max-width: 688px;
    }

    .additional-faq {
      .nav-arrow {
        background-repeat: no-repeat;
        background-size: 23px;
        width: 28px;
      }
    }

    textarea {
      font-size: 1.125rem;
      margin-top: map.get($spacers, 5);
      padding: 5px 10px;

      @include media-breakpoint-up(md) {
        margin-top: map.get($spacers, 4);
      }
    }

    .answer-text {
      height: 120px;

      @include media-breakpoint-up(md) {
        height: 80px;
      }
    }

    .question-text {
      height: 80px;

      @include media-breakpoint-up(md) {
        height: 40px;
      }
    }

    button {
      @include media-breakpoint-up(md) {
        margin-left: 30px;
      }
    }

    .add-event-container,
    .remove-event {
      margin-left: 30px;
    }

    .errorlist {
      margin-bottom: 0;
      padding-top: 0;

      li {
        font-size: 1.125rem;
        margin-bottom: 0;
      }
    }
  }

  .edit-charity-or-vendor-basics {
    .el-input-group {
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 562px;
      }
    }

    .overview-photo-container {
      &.has-photo {
        > div {
          &:first-child {
            width: 100%;
          }
        }

        .photo-container {
          max-width: 100%;

          @include media-breakpoint-up(lg) {
            margin-left: 40px;
          }
        }
      }
    }

    .negative-margin {
      @include media-breakpoint-up(lg) {
        margin-top: -55px;
      }
    }
  }
}

.uploaded-charity-or-vendor-photo-container {
  @include el-border;

  height: 280px;
  margin-bottom: 20px;
  padding: 5px;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 20px;
    width: 373px;
  }

  .img-fluid {
    max-height: 100%;
  }
}
