.edit-success-link {
  color: $success-green;

  &:hover {
    color: $success-green;
    text-decoration: underline;
  }
}

.price-list {
  .estimated-prices-statement {
    margin-bottom: 10px;

    &.prices-edited {
      margin-bottom: 40px;
    }
  }

  .price-list-legend {
    align-items: baseline;
    justify-content: flex-start;

    img {
      margin-right: 10px;
    }
  }

  .price-list-subheader {
    margin-bottom: 40px;
  }

  .price-list-service-filters {
    height: 328px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      height: 162px;
    }

    .custom-select-with-caret {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 309px;
      }

      @include media-breakpoint-up(lg) {
        width: 270px;
      }
    }
  }

  .price-list-table {
    margin-bottom: 40px;

    tr {
      td {
        padding: 0;
      }

      &:not(.pricing-total-row) {
        &:hover {
          background-color: $table-hover-gray;
          cursor: pointer;
        }
      }
    }

    .pricing-primary-cell {
      .pricing-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div,
        a {
          padding: 20px;

          @include media-breakpoint-between(xs, md) {
            width: 100%;
          }
        }

        > div {
          display: flex;

          @include media-breakpoint-between(xs, md) {
            padding-bottom: 13px;
          }
        }

        a {
          padding-left: 39px;

          @include media-breakpoint-up(md) {
            padding-left: 0;
          }

          @include media-breakpoint-between(xs, md) {
            padding-top: 0;
          }
        }

        img {
          margin-right: 10px;

          &.price-row-caret {
            margin-top: 6px;
          }

          &.rotated {
            transform: rotate(90deg);
          }
        }
      }

      .pricing-tip {
        color: $lighter-gray-text;
        font-size: 1.125rem;
        margin: 10px 0;
        padding: 0 20px;
      }

      .pricing-marketplace-message {
        font-size: 1rem;
        padding: 10px 10px 10px 39px;

        .link-secondary {
          font-size: 1rem;
        }
      }
    }

    .pricing-secondary-cell {
      text-align: left;
      width: 120px;

      @include media-breakpoint-up(md) {
        width: 218px;
      }

      > div {
        padding: 20px;
        padding-right: 24px;
      }

      img {
        margin-right: 10px;
        margin-top: -4px;
      }

      .circle-less {
        padding-left: 47px;
      }

      .marketplace-average-price {
        font-size: 1rem;
        padding: 22.5px 24px 21.5px 47px;

        @include media-breakpoint-up(md) {
          padding: 10px 24px 10px 47px;
        }
      }
    }

    .marketplace-pricing-row {
      background-color: $light-teal;
    }

    .pricing-total-row {
      background-color: $table-gray;
      font-weight: $font-weight-semibold;

      > td {
        padding: 20px;
      }

      .pricing-secondary-cell {
        padding-left: 47px;
        padding-right: 24px;
      }
    }

    &.prices-edited-table {
      td {
        border-color: $medium-blue;
        height: 70px;
        vertical-align: middle;
      }

      tr {
        &:not(.pricing-total-row) {
          &:hover {
            background-color: $light-blue;
            cursor: pointer;
          }
        }
      }

      .pricing-total-row {
        td {
          background-color: $light-blue;

          &.pricing-secondary-cell {
            vertical-align: middle;
          }
        }
      }

      .not-available {
        td {
          color: $lighter-gray-text;
        }
      }
    }
  }
}

.fh-products-cta {
  @include el-border-top;

  margin-bottom: 40px;
  padding-top: 40px;

  h2 {
    margin-bottom: 10px;
  }

  .fh-product-images {
    justify-content: space-between;
    margin: 20px 0;

    .fh-product-image {
      height: 95px;
      margin: 0;

      // since the images are within an secondary-link anchor, lets reset the image width
      width: auto;

      @include media-breakpoint-up(md) {
        height: auto;
      }
    }
  }
}

.business-memorials-wrapper {
  @include el-light-border-top;

  flex-direction: column;
  padding-top: 40px;

  h4 {
    margin-bottom: 0;

    @include media-breakpoint-between(xs, md) {
      font-size: 1.3125rem;
      padding-top: 40px;
    }
  }

  .business-memorial-container {
    @include el-light-border-bottom;

    flex: auto;
    flex-direction: column;
    opacity: 1;
    padding-bottom: 20px;
    transition: all 300ms ease-in-out;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }

    &:nth-last-child(2) {
      @include el-light-border-bottom;

      padding-bottom: 20px;

      @include media-breakpoint-up(md) {
        border: 0;
        padding-bottom: 0;
      }
    }

    &.htmx-added {
      flex: 0 0 0;
      opacity: 0;
    }

    a {
      font-size: 1.3125rem;
      line-height: 0;
    }

    .business-memorial-content-image-wrapper {
      padding-top: 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: auto;
      }

      .business-memorial-image-wrapper {
        margin-right: 20px;

        .business-memorial-image {
          background-position: center;
          background-size: cover;
          border-radius: $border-radius-sm;
          height: 70px;
          width: 95px;
        }
      }
    }
  }

  .business-memorial-phone-wrapper {
    flex-direction: column;
    margin-top: 40px;

    @include media-breakpoint-between(xs, md) {
      padding: 0 0 40px;
    }

    .light-gray-label {
      display: none;
    }

    .business-details-item {
      img {
        display: none;
      }
    }
  }
}

.pricing-donations-cta {
  @include el-border-top;

  padding-top: 20px;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }

  h2 {
    margin-bottom: 10px;

    @include media-breakpoint-between(xs, md) {
      padding-top: 20px;
    }
  }

  a {
    display: block;
  }

  .link-secondary {
    margin: 20px 0 40px;
  }
}

.fh-review-page {
  h1 {
    font-size: 1.75rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: $font-weight-semibold;
    padding-top: 40px;
  }

  p {
    margin-bottom: 0;
    padding-top: 10px;

    @include media-breakpoint-up(md) {
      padding-top: 20px;
    }
  }

  .search-header {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  .search-business-wrapper {
    margin-top: 20px;
  }

  .fh-location {
    align-items: center;
    padding-top: 0;

    img {
      float: left;
      margin-right: 10px;
      margin-top: 3px;
    }
  }

  .fh-address {
    padding-left: 10px;
  }

  .review-not-possible {
    padding-top: 40px;
  }

  .review-form-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 20px;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 40px;
    }

    .el-input-group {
      margin-bottom: 20px;
    }

    .btn-form-submit {
      padding: 0;
    }
  }
}

.star-rating {
  flex-direction: row-reverse;
  justify-content: flex-end;

  label {
    font-size: 0;
    height: 40px;
    width: 45px;
  }

  &:not(:checked) {
    > input {
      display: none;
    }

    > label {
      background: left / 40px 40px no-repeat url("../svg/gray-star.svg");
      cursor: pointer;
    }
  }

  > input {
    &:checked {
      ~ label {
        background: left / 40px 40px no-repeat url("../svg/gold-star.svg");
      }
    }
  }

  &:hover {
    > input {
      &:checked {
        ~ label {
          background: left / 40px 40px no-repeat url("../svg/gray-star.svg");
        }

        + label {
          &:hover {
            background: left / 40px 40px no-repeat url("../svg/gold-star.svg");
          }
        }
      }
    }

    > label {
      &:hover {
        background: left / 40px 40px no-repeat url("../svg/gold-star.svg") !important;

        ~ label {
          background: left / 40px 40px no-repeat url("../svg/gold-star.svg");
        }
      }
    }
  }
}
