// Colors

// stylelint-disable color-no-hex

$primary-brand-color: #7f465c;
$primary-brand-highlight-color: #fcfafb;
$faded-primary-brand-color: #e0dce0;

$secondary-brand-color: #17b3bf;
$secondary-brand-active-color: #dff4f5;
$secondary-brand-highlight-color: #f4fafc;
$darker-secondary-brand-color: #0eaab6;

$light-gray: #f5f5f5;
$medium-gray: #e7e7e7;
$darker-gray: #8e8e8e;
$table-gray: #eee;
$table-border-gray: #dee2e6;
$table-hover-gray: #f7f7f7;
$input-border-color: #d5d5d5;
$slate-gray: #f2f5f8;
$dark-gray-text: #444;
$darker-gray-text: #333;
$light-gray-text: #666;
$lighter-gray-text: #757575;
$light-text-color: #f8f9fa;
$blue: #72a8de;
$blue-gray-background: #f7fbff;
$light-blue-gray-background: #fbffff;
$light-blue: #edf6ff;
$medium-blue: #a1cbf4;
$dark-blue: #3f8cd8;
$darker-blue: #2e7ac4;
$white-glass: rgba(255 255 255 / 0.65);
$white-overlay: rgba(255 255 255 / 0.85);
$white-transparent: rgba(255 255 255 / 0);
$error-red-background: #ffefef;
$secondary-nav-background: #e7ecf2;
$header-border-color: #dbdbdb;
$line-divider-color: #ccc;
$success-green: #155724;
$success-highlight-color: #ecfaef;
$facebook-blue: #3b5998;
$google-blue: #4285f4;
$very-light-gray: #fafafa;
$nearly-white-gray: #fdfdfd;
$teal: #3bc0c9;
$light-teal: #f3fbfb;
$warning-orange: #e6830b;
$disabled-button-gray: #ced4da;
$empty-list-gray: #adb5bd;
$secondary-button-gray: #6c757d;
$private-alert-red: #db6565;

$black-with-4-percent-opacity: rgba(0 0 0 / 0.04);
$black-with-5-percent-opacity: rgba(0 0 0 / 0.05);
$black-with-10-percent-opacity: rgba(0 0 0 / 0.1);
$black-with-12-percent-opacity: rgba(0 0 0 / 0.12);
$black-with-20-percent-opacity: rgba(0 0 0 / 0.2);
$black-with-33-percent-opacity: rgba(0 0 0 / 0.33);
$black-with-50-percent-opacity: rgba(0 0 0 / 0.5);
$black-with-85-percent-opacity: rgba(0 0 0 / 0.85);
$light-blue-with-95-percent-opacity: rgba(237 246 255 / 0.95);
$light-gray-with-90-percent-opacity: rgba(245 245 245 / 0.9);
$line-divider-color-with-50-percent-opacity: rgba(204 204 204 / 0.5);
$gray-with-100-percent-opacity: rgba(240 240 240 / 1);
$purple-with-10-percent-opacity: rgba(149 92 114 / 0.1);

$radial-gradient-blue: #539fea;
$radial-gradient-teal: #2fb0ba;
$radial-gradient-dark-teal: rgba(9 46 49 / 1);
$radial-gradient-very-dark-teal: #000d0f;

$svg-recolor-primary-brand-color: brightness(0%) saturate(100%) invert(28%) sepia(15%)
  saturate(1646%) hue-rotate(285deg) brightness(98%) contrast(81%);
$svg-recolor-secondary-brand-color: brightness(0%) saturate(100%) invert(63%) sepia(67%)
  saturate(3641%) hue-rotate(144deg) brightness(97%) contrast(82%);
$svg-recolor-light-gray: brightness(0%) saturate(100%) invert(37%) sepia(43%)
  saturate(0%) hue-rotate(272deg) brightness(99%) contrast(95%);
$svg-recolor-dark-gray-text: brightness(0%) saturate(100%) invert(23%) sepia(2%)
  saturate(29%) hue-rotate(3deg) brightness(99%) contrast(87%);
$svg-recolor-darker-gray: brightness(0%) saturate(100%) invert(64%) sepia(0%)
  saturate(76%) hue-rotate(164deg) brightness(88%) contrast(90%);
$svg-recolor-green: brightness(0%) saturate(100%) invert(41%) sepia(95%) saturate(3332%)
  hue-rotate(113deg) brightness(98%) contrast(103%);
$svg-recolor-orange: brightness(0%) saturate(100%) invert(57%) sepia(68%)
  saturate(3065%) hue-rotate(352deg) brightness(104%) contrast(98%);
$svg-recolor-info: brightness(0%) saturate(100%) invert(22%) sepia(81%) saturate(451%)
  hue-rotate(142deg) brightness(102%) contrast(97%);

// Measurements

$el-input-length: 472px;
$profile-photo-medium: 273px;
$el-button-length: 335px;
$el-small-button-length: 168px;
$zindex-default: 1;

// Shadows

$card-shadow-light: 0 0 6px 0 $black-with-5-percent-opacity;
$card-shadow: 0 0 10px 0 $black-with-10-percent-opacity;
$card-shadow-hover: 0 0 25px 0 $black-with-20-percent-opacity;
$private-alerts-card-shadow: 0 10px 10px 0 $black-with-33-percent-opacity;
$action-button-shadow: 0 0 4px 0 $black-with-33-percent-opacity;
$action-button-shadow-hover: 0 4px 8px 0 $black-with-50-percent-opacity;
