@use "sass:color";
@use "sass:math";

// Mixins

@mixin el-border {
  border: 1px solid $input-border-color;
}

@mixin el-secondary-border {
  border: 1px solid var(--secondary-brand-color);
}

@mixin el-border-bottom {
  border-bottom: 1px solid $input-border-color;
}

@mixin el-light-border-bottom {
  border-bottom: 1px solid $medium-gray;
}

@mixin el-border-top {
  border-top: 1px solid $input-border-color;
}

@mixin el-light-border-top {
  border-top: 1px solid $medium-gray;
}

@mixin el-border-left {
  border-left: 1px solid $input-border-color;
}

@mixin el-light-border-left {
  border-left: 1px solid $medium-gray;
}

@mixin el-light-border-right {
  border-right: 1px solid $medium-gray;
}

// new correct mixins for borders
@mixin light-gray-border-bottom {
  border-bottom: 0.5px solid $line-divider-color;
}

@mixin light-gray-border-top {
  border-top: 0.5px solid $line-divider-color;
}

@mixin background-img-aspect-ratio($width, $height) {
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  padding-top: math.percentage(math.div($height, $width));
  width: 100%;
}

// NOTE(eric): couldn't find a way to read an image's width/height in SASS
@mixin div-same-size-as-background-img($url, $width, $height) {
  @include background-img-aspect-ratio($width, $height);

  background-image: url($url);
}

@mixin article-thumbnail {
  max-height: 110px;

  &.featured-other {
    max-height: 132px;
  }

  .article-photo-wrapper {
    min-width: 109px;
    width: 50%;
  }

  .article-photo-preview {
    @include background-img-aspect-ratio(109px, 67px);

    background-size: cover;
    border-radius: 0;
  }

  .article-body-preview {
    margin: 0 20px;
    width: 100%;

    .article-title-preview {
      font-size: 1.125rem;
    }

    .article-content-preview {
      font-size: 0.9375rem;
    }
  }
}

// Placeholder selectors

%base-h1 {
  font-size: 1.75rem;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }
}

%base-h2 {
  font-size: 1.5rem;
  font-weight: $font-weight-semibold;
  line-height: 1.5;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}

%base-h3 {
  font-size: 1.125rem;
  font-weight: $font-weight-semibold;
  line-height: 1.5;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 1.3125rem;
  }
}

%base-h4 {
  font-weight: $font-weight-semibold;
  margin-bottom: 20px;
}

%base-label {
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
}

%base-text-note {
  color: $lighter-gray-text;

  &.black-text-note {
    color: $black;
  }

  // TODO: Stylelint is erroneously marking this as a duplicate selector
  // stylelint-disable-next-line no-duplicate-selectors
  &.error {
    color: $red;
  }
}

@mixin theme-variables($primary, $secondary, $primary-svg, $secondary-svg) {
  --primary-brand-color: #{$primary};
  --primary-brand-color-rgb: #{to-rgb($primary)};
  --primary-brand-color-light: #{color.change($primary, $lightness: 96%)};
  --primary-brand-color-muted: #{color.change($primary, $lightness: 87%)};
  --primary-brand-color-svg: #{$primary-svg};
  --secondary-brand-color: #{$secondary};
  --secondary-brand-color-light: #{color.change($secondary, $lightness: 98%)};
  --secondary-brand-color-svg: #{$secondary-svg};
}

@mixin theme($primary, $secondary) {
  .btn-primary {
    @include button-variant(
      $primary,
      $primary,
      $color: $white,
      $hover-color: $white,
      $active-color: $white,
      $disabled-color: $white
    );
  }

  .btn-outline-primary {
    @include button-outline-variant(
      $primary,
      $color-hover: $white,
      $active-background: $primary,
      $active-border: $primary,
      $active-color: $white
    );
  }

  .btn-secondary {
    @include button-variant(
      $secondary,
      $secondary,
      $color: $white,
      $hover-color: $white,
      $active-color: $white,
      $disabled-color: $white
    );
  }

  .btn-outline-secondary {
    @include button-outline-variant(
      $secondary,
      $color-hover: $white,
      $active-background: $secondary,
      $active-border: $secondary,
      $active-color: $white
    );
  }

  .btn-link {
    // NOTE: We've disabled link hover for now
    // NOTE: btn-link is really a button so there is an active and focus state
    // --#{$prefix}btn-hover-color: #{shade-color($primary, $link-shade-percentage)};
    // --#{$prefix}btn-active-color: #{shade-color($primary, $link-shade-percentage)};
    --#{$prefix}btn-color: #{$primary};
    --#{$prefix}btn-hover-color: #{$primary};
    --#{$prefix}btn-active-color: #{$primary};
    --#{$prefix}btn-disabled-color: #{$primary};
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(color.mix($white, $primary, 15%))};
  }
}

@mixin theme-preview($primary, $secondary) {
  $secondary-light: color.change($secondary, $lightness: 98%);

  input {
    &[type="radio"] {
      &:checked {
        ~ .cp-container {
          border-color: $secondary;
        }
      }
    }
  }

  .cp-navbar {
    .cp-navbar-logo {
      background-color: $primary;
    }
  }

  .cp-navbar-menu {
    .cp-navbar-menu-item {
      background-color: $primary;
    }
  }

  .cp-memorial-nav {
    .cp-memorial-nav-item {
      background-color: $primary;
    }
  }

  .cp-memorial-content {
    .cp-memorial-content-main {
      .cp-memorial-content-button {
        background-color: $secondary;
      }
    }

    .cp-memorial-content-sidebar {
      background-color: $secondary-light;
      border-color: $secondary;
    }
  }
}
